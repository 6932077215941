import React, { useEffect, useState } from "react";
import sanityClient from "../client.js";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
// import {
//   Accordion,
//   AccordionItem,
//   AccordionItemHeading,
//   AccordionItemButton,
//   AccordionItemPanel,
// } from 'react-accessible-accordion';
import { Typography, Box } from '@mui/material';
import 'react-accessible-accordion/dist/fancy-example.css';

export const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
export default function Faqs() {

  const [expanded, setExpanded] = useState("");

  const handleChange = (panel ) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  
  const carefaqs = [
     {
      
      'question':"Who will look after my Wordpress website?",
      'answer':"We believe in transparency and want you to be well-acquainted with your collaborators. All tasks are handled in-house, with no reliance on outsourcing, by our dedicated team located in New Zealand."
    },
     {

      'question':" Will I still be able to update my website myself?",
      'answer':"Certainly, Do note that occasionally, updates might introduce unforeseen issues on your site. As your site expands and becomes more intricate, the likelihood of encountering such unintended consequences amplifies, particularly if there's an increase in software components being used. We'll ensure that backups for both your website files and database are set up prior to initiating any modifications.Websites can break. it's not the most pleasant news, it's a common occurrence for site updates to disrupt themes, page layouts, or other tailor-made elements on your site. This is a scenario we encounter regularly.Though updates and backups might seem simple, we always gear up for potential hitches, ensuring we're prepared for any unexpected turns. "
    },
    {
      'question':"My website is broken will a care plan fix it?",
      'answer':"If you're enrolled in a care plan, such situations are less likely to arise. However, in the rare event of an issue, we've got backups ready, along with the necessary processes to address it promptly! For those not yet on our care plans, a one-time website assessment and Optimisation service is required. We'll evaluate the current state, formulate a strategy, and if the problem can be resolved within 2 hours, we'll handle it. Otherwise, we'll present a detailed quote for its resolution. Inquire about your website Optimisation & assessment service now!"

    },
    {
      'question':"Am I locked into a contract?",
      'answer':"No. We deeply care about your website and business, and it would be disheartening to see you leave. Our goal isn't to bind anyone to a plan unwillingly. If you wish to cancel, simply send us an email. We'll then inform you about any licenses you might need to procure and promptly halt any upcoming payments."
    },
    {
      'question':"What if I need more hours or more support?",
      'answer':"Should your website needs exceed the allotted monthly hours, we'll promptly inform you. We do have packages for purchasing hours in bulk. Any unused time from these bulk hours can be carried forward for future use. For substantial tasks, we'll provide a detailed quotation for your approval before moving forward. If you require an isolated batch of hours, we'll ensure to provide an estimate before surpassing your monthly allowance."
    },
    {
      'question':"Do you offer web hosting?",
      'answer':"We sure do, our hosting starts from $190 per year."
    },
    {
      'question':"My site was built else where can I be on a care plan?",
      'answer':"Yes, your website will need to have been built on WordPress and we will require all of your CMS, Hosting & FTP login details in order to begin."
    },
  ]
  
  return (
    <div className="default-sec faqs-section lightGrayBg"  >
      <div className="default-sec-overlay">
        <div className="default-sec-content text-point accordion_wp">
          <div className="default-sec-wrap inner-text-point-left">
            <h2 className="center">Website Care Plans <span className="textGreen">FAQ</span></h2>
            <Box sx={{ display: 'flex', flexWrap: "wrap" }} className='accordion_inside faqs-accordion'>
              {carefaqs &&
                carefaqs.map((faqs, i) => (
                  <Box className='testimonial-wrapper' key={i}>
                  <Accordion expanded={expanded === i} onChange={handleChange(i)}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className="faqs-accordion-header">
                      <Typography> {faqs.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>{faqs.answer}</Typography>
                    </AccordionDetails>
                  </Accordion>
                  </Box>
                  )
                )
              }
              </Box>
          </div> 
        </div>
      </div>
    </div>
  );
}