import { Grid, Typography } from '@mui/material';
import React,{useState} from 'react';
import { Link } from "react-router-dom";
import Helmet from "../components/HelmetTag";
import "../assets/css/seo.scss";
import BannerLanding from "../components/BannerLanding";
import AllBlog from '../components/AllBlog';
import AllTestimonials from "../components/AllTestimonials.js";
import Faqs2 from "../components/Faqs2.js";
import SeoForm from "../components/SeoForm.js";
import Banner from "../components/Banner"
import CtaForm from "../components/CtaForm.js";
import Clients from "../components/Clients.js";
import SeoPlan from "../components/SeoPlan.js";
import NetbloomOG from "../assets/images/Netbloom-OG.jpg";
import bannerBg from "../assets/images/seo-bg.webp";
import phone from "../assets/images/phone.webp";
import { MdSearch, MdInsights, MdArrowRightAlt } from "react-icons/md";
import { TbTargetArrow, TbLink } from "react-icons/tb";
import { HiOutlineDocumentText, HiOutlineLightBulb,HiOutlineFastForward,HiOutlineChartPie,HiOutlineDocumentSearch,HiOutlineUserGroup,HiOutlinePresentationChartLine,HiDocumentReport,HiClipboardList } from "react-icons/hi";
import { TiSocialFacebook } from "react-icons/ti";
import { HashLink } from 'react-router-hash-link';

const SEOService = () => {
    const [plan,setPlan] = useState(null);
  document.body.classList.remove(
    "home",
    "single-blog",
    "main-case-study",
    "case-study",
    "single-case-study",
    "web-design",
    "portfolio",
    "ecommerce",
    "about",
    "location",
    "blog",
    "main-blog",
    "location"
  );
  document.body.classList.add("seo");
  const planSelected = (index) => { // the callback. Use a better name
    console.log(index)
    setPlan(index);
    
  };
  return (
    <div className='seo page-content'>
      <Helmet
      pageTitle="Expert SEO Services & Consultancy in NZ & AU | Netbloom"
      title="Expert SEO Services & Consultancy in NZ & AU | Netbloom"
      description="Discover the power of SEO with Netbloom in NZ and AU—customised plans for businesses and enterprises. Drive growth with our expert consultancy. Schedule now."
      robots = "follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
      locale="en_US"
      type="article"
      url="https://netbloom.co.nz/seo-services-nz/"
      site_name="Netbloom"
      updated_time="2023-01-17T06:27:38+00:00"
      image ={NetbloomOG}
      imgsecure_url={NetbloomOG}
      imgwidth="1200"
      imgheight="627"
      imgalt="New Zealand SEO Services"
      imgtype="image/jpeg"
      artpublished_time="2022-08-22T01:04:07+00:00"
      artmodified_time="2023-01-17T06:27:38+00:00"
      twcard="summary_large_image"
      twtitle="SEO Services NZ | Affordable SEO Specialist | Netbloom"
      twdescription="New Zealand&#039;s finest SEO services utilise trust & authority metrics to deliver measurable results. We offer proven SEO strategies for Auckland & across NZ."
      twimage={NetbloomOG}
      twlabel1="Written by"
      twdata1="Matthew J"
      twlabel2="Time to read"
      twdata2="2 minutes"
      />
      <div className="banner bn-landing" style={{backgroundImage: `url(${bannerBg})`}}>
        <BannerLanding 
          pageTitle='SEO Project Support and Consultancy - NZ & AU'
          subHeading = 'Quality Support coupled with a Holistic SEO Approach'
          btnText = 'Schedule a free consultation'
        />
      </div>
      <div className='clients-box default-sec-content' data-aos="fade">
        <div className='shadow-box center'>
          <p>We grow businesses of all shapes and sizes</p>
          <Clients />
        </div>
      </div>
      <Grid container className='services-wrap sticky-wrap' columnSpacing={0} id='seoform'>
        <Grid item md={4} sm={4} xs={12} className='con-left sticky' data-aos="fade-right">
          <h2>The place for all things SEO in New Zealand</h2>
          <p>SEO is all about growth. Growth for your business, your reputation, for you. In the world we live in today, however, this kind of growth will not come easy. Everyone is trying to grow their business, and everyone is looking for that one edge, that one advantage over the others that will get them a leg up the competition.
We give you that advantage.</p>
          <HashLink smooth to="#ctaform" className='btn'>Schedule a free consultation</HashLink>
        </Grid>
        <Grid item md={8} sm={12} xs={12} className='con-right'>
          <div className='center mb-20' >
            <SeoForm selectedPlan = {plan}/>
          </div>
          <Grid container spacing={6} className='seo-for'>
            <Grid item md={6} sm={6} xs={6}>
              <div className='seo-for-item'>
                <h3>Local SEO</h3>
                <p>Local businesses, like a plumber or a boutique store, need more customised SEO services that help them get discovered by customers in the area</p>
                {/* <Link to="#" className="btn outline" title="Learn more">Learn more <MdArrowRightAlt/> </Link> */}
              </div>
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <div className='seo-for-item'>
                <h3>SEO for MSMEs</h3>
                <p>Micro, small and medium businesses often don’t have a sizable budget for organic visibility, and need their dollars to go a long way</p>
                {/* <Link to="#" className="btn outline" title="Learn more">Learn more <MdArrowRightAlt/> </Link> */}
              </div>
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <div className='seo-for-item'>
                <h3>SEO for enterprises</h3>
                <p>Larger companies need SEO support and at times internal training to ensure they stay at the top of search engine results at all times</p>
                {/* <Link to="#" className="btn outline" title="Learn more">Learn more <MdArrowRightAlt/> </Link> */}
              </div>
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <div className='seo-for-item'>
                <h3>International SEO</h3>
                <p>If your business is spread across countries or focused outside of New Zealand, your website will need to be optimised for the respective country or countries</p>
                {/* <Link to="#" className="btn outline" title="Learn more">Learn more <MdArrowRightAlt/> </Link> */}
              </div>
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <div className='seo-for-item'>
                <h3>Citations & backlinking</h3>
                <p>An important component of organic visibility is offsite SEO, which includes building directory links and quality backlinks on other websites to bolster your domain authority</p>
                {/* <Link to="#" className="btn outline" title="Learn more">Learn more <MdArrowRightAlt/> </Link> */}
              </div>
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <div className='seo-for-item'>
                <h3>eCommerce SEO</h3>
                <p>eCommerce businesses can harness the power of SEO to improve both conversions and overall basket size of orders</p>
                {/* <Link to="#" className="btn outline" title="Learn more">Learn more <MdArrowRightAlt/> </Link> */}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
          <SeoPlan planSelected={planSelected} />
      </Grid>
      
     
      <div className='default-sec darkblueBg our-services'>
        <div className="default-sec-overlay">
          <div className="default-sec-content">
            <div className="default-sec-wrap">
              <Grid container className="textWhite" rowSpacing={4} columnSpacing={4}>
                <Grid item xs={12} className="inner-text-point-left center" >
                  <h3>Our <span className='textGreen'>SEO</span> Process</h3>
                  <p className='textWhite sub-title'>Customised and Tailor-fit SEO Solutions to Cater to your Business Needs.</p>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className='services-item'>
                  <div className='services-item-wrap'>
                    <HiClipboardList />
                    <h4>SEO Audit</h4>
                    <p className="textWhite">Before starting the SEO process, it’s important to conduct an SEO audit to understand the current state of the website, identify any potential issues and scope out opportunities.</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className='services-item'>
                <div className='services-item-wrap'>
                    <MdSearch />
                    <h4>Keyword Research</h4>
                    <p className="textWhite">Here, we will not only find the most relevant and high-traffic keywords for your business, but also focus on long-tail keywords and semantic keywords to capture a broader range of search queries.</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className='services-item'>
                  <div className='services-item-wrap'>
                    <TbTargetArrow />
                    <h4>Competitor Analysis</h4>
                    <p className="textWhite">In addition to understanding what your competitors are doing well, we’ll also identify their weaknesses and leverage them to your advantage.</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className='services-item'>
                  <div className='services-item-wrap'>
                    <HiOutlineDocumentText />
                    <h4>On-Page Optimisation</h4>
                    <p className="textWhite">Besides optimizing the content, we’ll also focus on meta tags, URL structure, internal linking, mobile compatibility, and structured data markup.</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className='services-item'>
                  <div className='services-item-wrap'>
                    <TbLink />
                    <h4>Technical SEO</h4>
                    <p className="textWhite">This includes optimizing XML sitemaps, robot.txt files, and ensuring that your website is secure, indexable and crawlable.</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className='services-item'>
                  <div className='services-item-wrap'>
                    <HiOutlineFastForward />
                    <h4>Page Speed Enhancement</h4>
                    <p className="textWhite">As well as improving the website’s speed, we’ll also optimise its performance on mobile devices.</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className='services-item'>
                  <div className='services-item-wrap'>
                    <HiOutlineChartPie />
                    <h4>Content Strategy and Optimisation</h4>
                    <p className="textWhite">Instead of simply generating articles or blog posts, we’ll create a comprehensive content strategy that aligns with your business goals and audience needs.</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className='services-item'>
                  <div className='services-item-wrap'>
                    <HiOutlineDocumentSearch />
                    <h4>Off-Page SEO</h4>
                    <p className="textWhite">Along with link building, we’ll also focus on brand building, guest blogging, influencer outreach, and managing your online reputation.</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className='services-item'>
                  <div className='services-item-wrap'>
                    <HiOutlineUserGroup />
                    <h4>Local SEO</h4>
                    <p className="textWhite">Apart from optimizing Google Business pages, we’ll also focus on other local directories and manage online reviews.</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className='services-item'>
                  <div className='services-item-wrap'>
                    <HiOutlinePresentationChartLine />
                    <h4>Performance Monitoring and Optimisation</h4>
                    <p className="textWhite">We’ll use advanced analytics to monitor your SEO progress and make data-driven decisions.</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className='services-item'>
                  <div className='services-item-wrap'>
                    <HiDocumentReport />
                    <h4>Rank Reporting & Consulting</h4>
                    <p className="textWhite">In addition to providing monthly SEO reports, we’ll also offer strategic advice and guidance on how to continually improve your SEO.</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className='services-item'>
                  <div className='services-item-wrap'>
                  <MdInsights />
                    <h4>Conversion Rate Optimisation</h4>
                    <p className="textWhite">Lastly, we’ll focus on turning your traffic into conversions, because SEO is not just about driving traffic, but also about driving sales.</p>
                  </div>
                </Grid>
                <Grid item xs={12} className="inner-text-point-left center" >
                  <HashLink smooth to="#ctaform" className='btn'>Schedule a free consultation</HashLink>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <AllTestimonials />
      <Faqs2 />
      <div className='default-sec'>
        <div className="default-sec-overlay">
          <div className="default-sec-content">
            <div className="default-sec-wrap">
              <h2 className='title'>Latest Blog Posts</h2>
              <AllBlog categoryFilter={"all"} showingPost={"limited"} />
            </div>
          </div>
        </div>
      </div>
      <div id="ctaform">
      <CtaForm />
      </div>
    </div >
  )
}

export default SEOService
