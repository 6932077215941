import React from 'react'
import { Grid } from '@mui/material';
import technical from '../assets/icons/settings-gears.png'
import peoples from '../assets/icons/people.png'
import developer from '../assets/icons/developer.png'
import { Link } from "react-router-dom";
export default function WebSupportPlans() {
  return (
            <section className='website_features_wp'>
                <Grid container className="text-point website_feat_conte">
                    <Grid item xs={12} className="website_features_title">
                        <div className='website_features_title_inner'>
                        <h2 className='title'>WordPress Maintenance & Support Plans</h2>
                            <p className='sub-head textGreen'>Keep your website in top form 24/7 with Netbloom's support plans.</p>
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className="text-point website_features">
                    <Grid item md={4} sm={6} xs={12} className="features_box" >
                        <div className='features_box_inner'>
                            <div className='website_features_icon'>
                                <img src={technical} alt="technical"/>
                            </div>
                            <h3>Essentials</h3>
                            <h3 className='price'>$85/month</h3>
                            <p>Ideal for offloading maintenance tasks and keeping your site in top shape</p>
                            
                            <ul>
                                <li>A Dedicated Site Manager</li>
                                <li>Website Hosting</li>
                                <li>Plugin updates</li>
                                <li>Daily Backups</li>
                                <li>Daily Security Scans</li>
                                <li>Custom Dashboard to track important KPIs</li>
                                <li>Website Technical Support</li>
                            </ul>
                            <p><Link to="#" className="greenButton btn">Enquire</Link></p>
                        </div>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12} className="features_box">
                        <div className='features_box_inner'>
                            <div className='website_features_icon'>
                                <img src={peoples} alt="peoples"/>
                            </div>
                            <h3>Hands-on</h3>
                            <h3 className='price'>$140/month</h3>
                            <p>Ideal for designing and building new pages or adding content, images and videos</p>
                           
                            <ul>
                                <li> Everything in Care Plan: Essentials</li>
                                <li> Recommend site updates as needed</li>
                                <li> Summarised analytics reporting</li>
                                <li> One hour of consulation per month</li>
                                <li>Custom Page Design & Build</li>
                                <li>SEO Suggestions</li>
                                <li>Keyword Research</li>
                                
                            </ul>
                            
                            <p><Link to="#" className="greenButton btn">Enquire</Link></p>
                        </div>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12} className="features_box">
                        <div className='features_box_inner'>
                            <div className='website_features_icon'>
                                <img src={developer} alt="developer"/>
                            </div>
                            <h3>Dedicated Support</h3>
                            <h3 className='price'>$600/month</h3>
                            <p>Ideal for large sites or scheduled calls and planning monthly projects</p>
                            
                            <ul>
                                    <li> Everything in Care Plan: Hands-on</li>
                                    <li>A Dedicated Task Board</li>
                                    <li>Schedule Monthly Tasks</li>
                                    <li>Custom Task Workflows</li>
                                    <li>Scheduled Calls</li>
                                    <li> Website keyword tracking</li>
                                    <li>SEO Monitoring & Reporting</li>
                            </ul>
                            <p><Link to="#" className="greenButton btn">Enquire</Link></p>
                        </div>
                    </Grid>
                </Grid>
            </section >
  )
}
