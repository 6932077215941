import React from "react";
import img404 from '../assets/images/404.webp';
import { Grid } from '@mui/material';
export default function NotFound() {
    return (
        <div className="banner">
            <div className="banner-overlay">
            <div className="main-content">
                    <div className="banner-con">
                    <Grid container>
                        <Grid item md={6} sm={6} xs={12}>
                            <img src={img404} alt="404" className="center" />
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <h1 className="mt-50">Oops! That page can’t be found.</h1>
                        </Grid>
                    </Grid>
                       
                    </div>
                </div>
            </div>
        </div>
    )
}