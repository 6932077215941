import React,{useState} from 'react';
import { Grid, Typography } from '@mui/material';
import { AiOutlineCloudServer, AiOutlineQuestionCircle, AiFillCheckCircle, AiOutlineCloseCircle} from "react-icons/ai";
import { HashLink } from 'react-router-hash-link';
import { Link } from "react-router-dom";
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import "../assets/css/seoPlan.scss";
function createData(name, small, higher, mid, high,enterprise) {
    return { name, small, higher, mid, high,enterprise };
  }



const rows = [
    createData('Dedicated Account Manager',<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71",fontSize:"40px" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />),
    createData('Monthly Consultation Meeting',<HighlightOffRoundedIcon style={{ color: "#e74c3c" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71",fontSize:"40px" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />),
    createData('Campaign Ranking for Up to ','7 Keywords','12 Keywords','17 Keywords', '?','?'),
    createData('Keyword Research Analysis ',<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71",fontSize:"40px" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />),
    createData('Inbound Link Building',<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71",fontSize:"40px" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />),
    createData('Optimised Blog Content','3','5','9',<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71"}} />),
    createData('Google Analytics 4 Integration',<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71",fontSize:"40px" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />),
    createData('Google Search Console Integration',<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71",fontSize:"40px" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />),
    createData('Google Maps & Google My Business Set Up',<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71",fontSize:"40px" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />),
    createData('Local Search Optimisation',<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71",fontSize:"40px" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />),
    createData('Technical Website Analysis / SEO Audit',<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71",fontSize:"40px" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />),
    createData('Landing Page Conversion Optimisation',<HighlightOffRoundedIcon style={{ color: "#e74c3c" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71",fontSize:"40px" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />),
    createData('Mobile Responsiveness Optimisation',<HighlightOffRoundedIcon style={{ color: "#e74c3c" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71",fontSize:"40px" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />),
    createData('Page Speed Enhancements',<HighlightOffRoundedIcon style={{ color: "#e74c3c" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71",fontSize:"40px" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />),
    createData('Citation Building',<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71",fontSize:"40px" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />),
    createData('Page Content Audits',<HighlightOffRoundedIcon style={{ color: "#e74c3c" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71",fontSize:"40px" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />),
    createData('Social Signals',<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71",fontSize:"40px" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />),
    createData('Guest Posting',<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71",fontSize:"40px" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />),
    createData('PR Writing and Distribution',<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71",fontSize:"40px" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />),
    createData('Social Foundations',<HighlightOffRoundedIcon style={{ color: "#e74c3c" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71",fontSize:"40px" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />),
    createData('Competition Analysis',<HighlightOffRoundedIcon style={{ color: "#e74c3c" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71",fontSize:"40px" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />),
    createData('Schema Markup Implementation',<HighlightOffRoundedIcon style={{ color: "#e74c3c" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71",fontSize:"40px" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />),
    createData('Backlinks Audit',<HighlightOffRoundedIcon style={{ color: "#e74c3c" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71",fontSize:"40px" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />),
    createData('Citation Cleanup ',<HighlightOffRoundedIcon style={{ color: "#e74c3c" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71",fontSize:"40px" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />),
    createData('Monthly Custom SEO Report',<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71",fontSize:"40px" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />,<CheckCircleOutlineRoundedIcon style={{ color: "#2ecc71" }} />),
    createData('Completion Time','30 Days','30 Days','30 Days','30 Days','30 Days'),

];
function SeoPlan({planSelected}) {
    return (
    <div className='default-sec seo-plan lightGrayBg'>
        <div className="default-sec-overlay">
          <div className="default-sec-content">
            <div className="default-sec-wrap center">
              <h2><span className='textGreen'>SEO</span> PLANS</h2>
                <p className='sub-head '>6-month contract (10% savings)</p>
                <p className='sub-head '> 12-month contract (15% savings)</p>
               
                    <Grid container className='plan-table desktop'>
                        <Grid item md={4.5} sm={4.5} xs={4.5}className='right service plan-table-col'>
                        <ul>
                            <li></li>
                            <li><h4>TASK</h4></li>
                            <li>Monthly Price</li>
                            {rows.map((row) => (
                                 <li>
                                    {row.name}
                                 <span className='tooltip'><AiOutlineQuestionCircle/>
                                     <p className='tip'>Lorem ipsum dolor sit amet consectetuer</p>
                                 </span>
                                 </li>
                            ))}
                            
                        </ul>
                        </Grid>
                        <Grid item md={1.5} sm={1.5} xs={1.5} className='center plan plan1 plan-table-col'>
                        <ul>
                            <li></li>
                            <li>Small Local Business</li>
                            <li>$550 - $1,200</li>
                            {rows.map((row) => (
                                 <li>{row.small}</li>
                            ))}
                            <li><HashLink smooth to="#seoform" onClick={() => {
              planSelected('small')}}  id='small' className="greenButton btn">
                                Get Started 
                                </HashLink></li>
                        </ul>
                        </Grid>
                        <Grid item md={1.5} sm={1.5} xs={1.5}  className='center plan plan2 plan-table-col bs-value '>
                        <ul>
                            <li className='best'>Best Value!</li>
                            <li >Higher Competition</li>
                            <li>$1,200 - $3,500 </li>
                            {rows.map((row) => (
                                 <li>{row.higher}</li>
                            ))}
                            
                            <li><HashLink smooth to="#seoform" onClick={() => {
              planSelected('highcompe')}} id='highcompe' className="greenButton btn ">Get Started</HashLink></li>
                        </ul>
                        </Grid>
                        <Grid item md={1.5} sm={1.5} xs={1.5}  className='center plan plan3 plan-table-col'>
                        <ul>
                            <li></li>
                            <li>Mid-Range</li>
                            <li>$3,500-$5,000 </li>
                            {rows.map((row) => (
                                 <li>{row.mid}</li>
                            ))}
                            
                            <li><HashLink smooth to="#seoform" onClick={() => {
              planSelected('midrange')}} id='midrange' className="greenButton btn">Get Started</HashLink></li>
                        </ul>
                        </Grid>
                        <Grid item md={1.5} sm={1.5} xs={1.5}  className='center plan plan3 plan-table-col'>
                        <ul>
                          <li></li>
                            <li>High-End</li>
                            <li>$5,500-$8,000</li>
                            {rows.map((row) => (
                                 <li>{row.high}</li>
                            ))}
                            
                            <li><HashLink smooth to="#seoform" onClick={() => {
              planSelected('highend')}} className="greenButton btn">Get Started</HashLink></li>
                        </ul>
                        </Grid>
                        <Grid item md={1.5} sm={1.5} xs={1.5}  className='center plan plan3 plan-table-col'>
                        <ul>
                            <li></li>
                            
                            <li>Enterprise</li>
                            <li>$10,000-$20,00</li>
                            {rows.map((row) => (
                                 <li>{row.enterprise}</li>
                            ))}
                            
                            <li><HashLink smooth to="#seoform" onClick={() => {
              planSelected('enterprise')}} className="greenButton btn">Get Started</HashLink></li>
                        </ul>
                        </Grid>
                    </Grid>
                    <Grid container className='plan-table mobile' spacing={4}>
                        <Grid item md={12} sm={12} xs={12} className='center plan plan1 plan-table-col'>
                            <ul>
                                <li></li>
                                <li><h4>Small Local Business</h4></li>
                                {rows.map((row) => (
                                
                                    <li>
                                         <span className='task'>{row.name}</span>
                                    <span className='tooltip'><AiOutlineQuestionCircle/>
                                        <p className='tip'>Lorem ipsum dolor sit amet consectetuer</p>
                                    </span>
                                    </li>
                                    
                                ))}
                                <li><HashLink smooth to="#seoform" className="greenButton btn">Get Started</HashLink></li>
                            </ul>
                        </Grid>
                    </Grid>
                    <Grid container className='plan-table mobile' spacing={4}>
                        <Grid item md={12} sm={12} xs={12} className='center plan plan2 plan-table-col'>
                            <ul>
                            <li className='best'>Best Value!</li>
                                <li><h4>Higher Competition</h4></li>
                                {rows.map((row) => (
                                
                                    <li>
                                       <span className='task'>{row.name}</span> 
                                    <span className='tooltip'><AiOutlineQuestionCircle/>
                                        <p className='tip'>Lorem ipsum dolor sit amet consectetuer</p>
                                    </span>
                                    </li>
                                    
                                ))}
                                <li><HashLink smooth to="#seoform" className="greenButton btn">Get Started</HashLink></li>
                            </ul>
                        </Grid>
                    </Grid>
                    <Grid container className='plan-table mobile' spacing={4}>
                        <Grid item md={12} sm={12} xs={12} className='center plan plan3 plan-table-col'>
                            <ul>
                                <li></li>
                                <li><h4>Mid-Range</h4></li>
                                {rows.map((row) => (
                                
                                    <li>
                                        <span className='task'>{row.name}</span>
                                    <span className='tooltip'><AiOutlineQuestionCircle/>
                                        <p className='tip'>Lorem ipsum dolor sit amet consectetuer</p>
                                    </span>
                                    </li>
                                    
                                ))}
                                <li><HashLink smooth to="#seoform" className="greenButton btn">Get Started</HashLink></li>
                            </ul>
                        </Grid>
                    </Grid>
                    <Grid container className='plan-table mobile' spacing={4}>
                        <Grid item md={12} sm={12} xs={12} className='center plan plan3 plan-table-col'>
                            <ul>
                                <li></li>
                                <li><h4>High-End</h4></li>
                                {rows.map((row) => (
                                
                                    <li>
                                        <span className='task'>{row.name}</span>
                                    <span className='tooltip'><AiOutlineQuestionCircle/>
                                        <p className='tip'>Lorem ipsum dolor sit amet consectetuer</p>
                                    </span>
                                    </li>
                                    
                                ))}
                                <li><HashLink smooth to="#seoform" className="greenButton btn">Get Started</HashLink></li>
                            </ul>
                        </Grid>
                    </Grid>
                    <Grid container className='plan-table mobile' spacing={4}>
                        <Grid item md={12} sm={12} xs={12} className='center plan plan3 plan-table-col'>
                            <ul>
                                <li></li>
                                <li><h4>Enterprise</h4></li>
                                {rows.map((row) => (
                                
                                    <li>
                                        <span className='task'>{row.name}</span> 
                                    <span className='tooltip'><AiOutlineQuestionCircle/>
                                        <p className='tip'>Lorem ipsum dolor sit amet consectetuer</p>
                                    </span>
                                    </li>
                                    
                                ))}
                                <li><HashLink smooth to="#seoform" className="greenButton btn" >Get Started</HashLink></li>
                            </ul>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    </div>
        
    );
}

export default SeoPlan
