import { Grid, Typography } from '@mui/material';
import React from 'react';
import { Link } from "react-router-dom";
import Helmet from "../components/HelmetTag";
import "../assets/css/care.scss";
import AllBlog from '../components/AllBlog';
import AllTestimonials from "../components/AllTestimonials.js";
import Faqs from "../components/Faqs.js";
import CtaForm from "../components/CtaForm.js";
import NetbloomOG from "../assets/images/Netbloom-OG.jpg";
import phone from "../assets/images/phone.webp";
import google from "../assets/images/google reviews.webp";
import { AiOutlineCloudServer, AiOutlineQuestionCircle, AiFillCheckCircle, AiOutlineCloseCircle} from "react-icons/ai";
import { TbLock, TbWebhook, TbUserPlus } from "react-icons/tb";
import { MdSpeed, MdSupport,MdUpdate,MdSupervisedUserCircle } from "react-icons/md";
import { HashLink } from 'react-router-hash-link';
import BannerLanding from "../components/BannerLanding";
import update from "../assets/images/update.webp";
import relax from "../assets/images/relax-com.webp";
import WebSupportPlans from '../components/WebSupportPlans';
const CarePlan = () => {
  document.body.classList.remove(
    "home",
    "single-blog",
    "main-case-study",
    "case-study",
    "single-case-study",
    "web-design",
    "portfolio",
    "ecommerce",
    "about",
    "location",
    "blog",
    "main-blog",
    "location",
    "seo"
  );
  document.body.classList.add("care");

  return (
    <div className='care page-content'>
      <Helmet
      pageTitle="Top-Notch Website Maintenance Services in NZ | Netbloom"
      title="Top-Notch Website Maintenance Services in NZ | Netbloom"
      description="Ensure peak performance with our website maintenance services in NZ: daily backups, security scans & tailored support plans. Choose reliability for your site."
      robots = "follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
      locale="en_US"
      type="article"
      url="https://netbloom.co.nz/care-plan/"
      site_name="Netbloom"
      updated_time="2023-01-17T06:27:38+00:00"
      image ={NetbloomOG}
      imgsecure_url={NetbloomOG}
      imgwidth="1200"
      imgheight="627"
      imgalt="New Zealand Website Care Plan"
      imgtype="image/jpeg"
      artpublished_time="2022-08-22T01:04:07+00:00"
      artmodified_time="2023-01-17T06:27:38+00:00"
      twcard="summary_large_image"
      twtitle="Website Care Plan NZ | Affordable SEO Specialist | Netbloom"
      twdescription="New Zealand&#039;s finest SEO services utilise trust & authority metrics to deliver measurable results. We offer proven SEO strategies for Auckland & across NZ."
      twimage={NetbloomOG}
      twlabel1="Written by"
      twdata1="Matthew J"
      twlabel2="Time to read"
      twdata2="2 minutes"
      />
      <div className="banner">
        <div className="banner-overlay">
          <div className="main-content">
            <div className="banner-con">
              <h1>Wordpress Maintenance Service  & Website Support Care Plan </h1>
              <p className='textWhite'>Absolute assurance that your WordPress site receives the care and focus it merits. Our NZ-based website maintenance service stands unmatched, offering continuous support and guidance.</p>
              <div className="flex-left btns">
                <HashLink  smooth to="#ctaform" className="greenButton btn ">Get Started</HashLink>  
                <img src={google} alt="Google Reviews" style={{display: 'block' }}/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='default-sec brief'>
        <div className="default-sec-overlay">
          <div className="default-sec-content">
            <div className="default-sec-wrap">
              <Grid container rowSpacing={8} className='con-right '>
                <Grid item md={12} sm={12} xs={12} className='flex-v-center content' data-aos="fade-right">
                  <h3 class="center">Our website upkeep <span className='textDarkBlue'>plans</span> are tailored to meet your business needs.</h3>
                  <p class="center col-100">Total assurance that your WordPress site is well-maintained and receiving the care it rightfully merits.</p>
                  <HashLink smooth  to="/web-maintenance-plans/#ctaform" className="greenButton btn center">
                  Get a Started
                </HashLink>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <div className='default-sec inclusions darkblueBg'>
        <div className="default-sec-overlay">
          <div className="default-sec-content">
            <div className="default-sec-wrap">
              <Grid container>
                <Grid item md={12} sm={12} xs={12}>
                  <h2 className='center textGreen'>Website Maintenance Overview:</h2>
                </Grid>
                <Grid item md={4} sm={6} xs={12} className='inclusions-item' data-aos="fade-left">
                  <AiOutlineCloudServer/>
                  <h3>Website Back-ups</h3>
                  <p>Ease your concerns about your WordPress website encountering issues. With our daily incremental backups, you're safeguarded against losing any content, photos, or documents. ​</p>
                </Grid>
                <Grid item md={4} sm={6} xs={12} className='inclusions-item' data-aos="fade-left">
                <MdSpeed/>
                  <h3>Speed & Performance</h3>
                  <p>Ensuring your website loads swiftly is crucial. We consistently monitor your site's performance every day. Continually, we seek methods to enhance its speed and elevate the overall user journey.</p>
                </Grid>
                <Grid item md={4} sm={6} xs={12} className='inclusions-item' data-aos="fade-left">
                  <TbLock/>
                  <h3>Security & 24/7 Monitoring</h3>
                  <p> Our primary responsibility is to keep your website safe, up-to-date, and secure. Defend your site against spam, malware, and various threats with daily scans, blocking unwanted intrusions, and continuous site monitoring.</p>
                </Grid>
                <Grid item md={4} sm={6} xs={12} className='inclusions-item' data-aos="fade-right">
                  <MdUpdate />
                  <h3>Website & WordPress Updates</h3>
                  <p>We diligently test and implement the newest WordPress updates on your website. This ensures you're consistently operating with the most recent and secure software, preventing potential compatibility challenges.</p>
                </Grid>
                <Grid item md={4} sm={6} xs={12} className='inclusions-item' data-aos="fade-right">
                  <MdSupport/>
                  <h3>Unrivalled Website Support</h3>
                  <p>With our WordPress care packages, you receive prompt assistance from a dedicated team of UK-based WordPress experts. We continually aim to go above and beyond in all our endeavors to meet your needs.</p>
                </Grid>
                <Grid item md={4} sm={6} xs={12} className='inclusions-item' data-aos="fade-right">
                  <MdSupervisedUserCircle/>
                  <h3>Experienced Professional Team</h3>
                  <p>At a reasonable cost, our comprehensive team is here to cater to your requirements. Let your website flourish through insightful strategies, and be updated with status report overviews detailing the tasks executed within your Maintenance Plan. We are here to look after your website</p>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <div className='default-sec support-plan' >
        <div className="default-sec-overlay">
          <div className="default-sec-content">
            <div className="default-sec-wrap center" data-aos="fade-up"  >
              <WebSupportPlans />
            </div>
          </div>
        </div>
      </div>
      <div className='default-sec pricing-plan lightGrayBg'>
        <div className="default-sec-overlay">
          <div className="default-sec-content">
            <div className="default-sec-wrap center">
              <p className='sub-head textGreen'>WEB SOLUTIONS FOR ANY BUSINESS</p>
              <h2 className='title'>Web Care Plans & Pricing</h2>
              <Grid container className='plan-table desktop'>
                <Grid item md={4.5} sm={4.5} xs={4.5} className='right service plan-table-col'>
                  <ul>
                    <li></li>
                    <li></li>
                    <li>Managed Hosting 
                    </li>
                    <li>Enterprise Transactional Email 
                    </li>
                    <li>SSL Setup & Integration 
                    </li>
                    <li>Malware Cleanup Guarantee 
                    </li>
                    <li> Server Firewall 
                    </li>
                    <li>Managed WordPress Updates 
                    </li>
                    <li>Basic SEO Configuration 
                    </li>
                    <li>Website Backups 
                    </li>
                    <li>Security Scans 
                    </li>
                    <li>Website Care Report 
                    </li>
                    <li>Website Hosting</li>
                    <li>A Dedicated Site Manager</li>
                    <li>Plugin updates</li>
                    <li>Daily Backups</li>
                    <li>Daily Security Scans</li>
                    <li>Custom Dashboard to track important KPIs</li>
                    <li>Website Technical Support</li>
                    <li>Minor Website Edits 
                    </li>
                    <li> Recommend site updates as needed</li>
                    <li> Summarised analytics reporting</li>
                    <li> One hour of consulation per month</li>
                    <li>Custom Page Design & Build</li>
                    <li>Image Optimisation 
                    </li>
                    <li>Advanced Google Analytics 
                    </li>
                    <li>eCommerce Reporting 
                    </li>
                    <li>24/7 Uptime Monitoring 
                    </li>
                    <li>Priority Support 
                    </li>
                    <li>Site Performance Monitoring 
                    </li>
                    <li>SEO Monitoring & Reporting 
                    </li>
                    <li>Quarterly Consulting Call 
                    </li>
                    <li>Schedule Monthly Tasks</li>
                    <li>Custom Task Workflows</li>
                    <li> Website keyword tracking</li>
                    
                  </ul>
                </Grid>
                <Grid item md={2.5} sm={2.5} xs={2.5} className='center plan plan1 plan-table-col'>
                  <ul>
                    <li></li>
                    <li><h4>Essentials</h4></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiOutlineCloseCircle className="no"/></li>
                    <li><AiOutlineCloseCircle className="no"/></li>
                    <li><AiOutlineCloseCircle className="no"/></li>
                    <li><AiOutlineCloseCircle className="no"/></li>
                    <li><AiOutlineCloseCircle className="no"/></li>
                    <li><AiOutlineCloseCircle className="no"/></li>
                    <li><AiOutlineCloseCircle className="no"/></li>
                    <li><AiOutlineCloseCircle className="no"/></li>
                    <li><AiOutlineCloseCircle className="no"/></li>
                    <li><AiOutlineCloseCircle className="no"/></li>
                    <li><AiOutlineCloseCircle className="no"/></li>
                    <li><AiOutlineCloseCircle className="no"/></li>
                    <li><AiOutlineCloseCircle className="no"/></li>
                    <li><AiOutlineCloseCircle className="no"/></li>
                    <li><AiOutlineCloseCircle className="no"/></li>
                    <li><HashLink smooth to="#ctaform" className="newBlueButton btn">$85</HashLink></li>
                  </ul>
                </Grid>
                <Grid item md={2.5} sm={2.5} xs={2.5} className='center plan plan2 plan-table-col'>
                  <ul>
                    <li className='best'>Best Value!</li>
                    <li><h4>Hands-on</h4></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiOutlineCloseCircle className="no"/></li>
                    <li><AiOutlineCloseCircle className="no"/></li>
                    <li><AiOutlineCloseCircle className="no"/></li>
                    <li><AiOutlineCloseCircle className="no"/></li>
                    <li><AiOutlineCloseCircle className="no"/></li>
                    <li><HashLink smooth to="#ctaform" className="newBlueButton btn">$140</HashLink></li>
                  </ul>
                </Grid>
                <Grid item md={2.5} sm={2.5} xs={2.5} className='center plan plan3 plan-table-col'>
                  <ul>
                    <li></li>
                    <li><h4>Dedicated Support</h4></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><AiFillCheckCircle className="yes"/></li>
                    <li><HashLink smooth to="#ctaform" className="newBlueButton btn">$600</HashLink></li>
                  </ul>
                </Grid>
              </Grid>

              <Grid container className='plan-table mobile' spacing={4}>
                <Grid item md={12} sm={12} xs={12} className='center plan plan1 plan-table-col'>
                  <ul>
                    <li></li>
                    <li><h4>Essentials</h4></li>
                    <li>No Annual Contract
                    </li>
                    <li>Managed Hosting
                    </li>
                    <li>Premium Licensing
                    </li>
                    
                    <li>SSL Setup & Integration
                    </li>
                    <li>Malware Cleanup Guarantee
                    </li>
                    <li> Server Firewall
                    </li>
                    <li>Managed WordPress Updates
                    </li>
                    <li>Basic SEO Configuration
                    </li>
                    <li>Website Backups
                    </li>
                    <li><HashLink smooth to="#ctaform" className="greenButton btn">$85/month</HashLink></li>
                  </ul>
                </Grid>
                <Grid item md={12} sm={12} xs={12} className='center plan plan2 plan-table-col'>
                  <ul>
                    <li className='best'>Best Value!</li>
                    <li><h4>Hands-on</h4></li>
                    <li>No Annual Contract
                    </li>
                    <li>Managed Hosting
                    </li>
                    <li>Premium Licensing
                    </li>
                    <li>Enterprise Transactional Email
                    </li>
                    <li>SSL Setup & Integration
                    </li>
                    <li>Malware Cleanup Guarantee
                    </li>
                    <li> Server Firewall
                    </li>
                    <li>Managed WordPress Updates
                    </li>
                    <li>Basic SEO Configuration
                    </li>
                    <li>Website Backups
                    </li>
                    <li>Security Scans
                    </li>
                    <li>Website Care Report
                    </li>
                    <li>Minor Website Edits
                    </li>
                    <li>Image Optimisation
                    </li>
                    <li>Advanced Google Analytics
                    </li>
                    <li>eCommerce Reporting
                    </li>
                    <li><HashLink smooth to="#ctaform" className="greenButton btn">$140/month</HashLink></li>
                  </ul>
                </Grid>
                <Grid item md={12} sm={12} xs={12} className='center plan plan3 plan-table-col'>
                  <ul>
                    <li></li>
                    <li><h4>Dedicated Support</h4></li>
                    <li>No Annual Contract
                    </li>
                    <li>Managed Hosting
                    </li>
                    <li>Premium Licensing

                    </li>
                    <li>Enterprise Transactional Email

                    </li>
                    <li>SSL Setup & Integration

                    </li>
                    <li>Malware Cleanup Guarantee

                    </li>
                    <li> Server Firewall

                    </li>
                    <li>Managed WordPress Updates

                    </li>
                    <li>Basic SEO Configuration

                    </li>
                    <li>Website Backups

                    </li>
                    <li>Security Scans

                    </li>
                    <li>Website Care Report

                    </li>
                    <li>Minor Website Edits

                    </li>
                    <li>Image Optimisation

                    </li>
                    <li>Advanced Google Analytics

                    </li>
                    <li>eCommerce Reporting

                    </li>
                    <li>24/7 Uptime Monitoring

                    </li>
                    <li>Priority Support

                    </li>
                    <li>Site Performance Monitoring

                    </li>
                    <li>SEO Monitoring & Reporting

                    </li>
                    <li>Quarterly Consulting Call

                    </li>
                    <li><HashLink smooth to="#ctaform" className="greenButton btn">$600/month</HashLink></li>
                  </ul>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <div className='default-sec webcare'>
        <div className="default-sec-overlay">
          <div className="default-sec-content">
            <div className="default-sec-wrap ">
              <Grid container rowSpacing={8} className='con-right'>
                <Grid item md={12} sm={12} xs={12} className='flex-v-center content center' data-aos="fade-right">
                  <h2>Ensuring your website remains current is vital for its optimal performance and ongoing success.</h2>
                  <p>Our WordPress maintenance service not only conserves your time and funds but also provides tranquillity, knowing seasoned professionals are diligently caring for your site.</p>
                </Grid>
                  <Grid item md={6} sm={6} xs={12} data-aos="fade-left">
                      <img src={update} alt="Website updates" />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12} data-aos="fade-right">
                      <h2>WordPress Website Updates & Software Improvements</h2>
                      <p>Whether it's minor tweaks, the addition of new features, or major overhauls, we're at your service to optimise your website's potential.</p>
                      <p>With our website maintenance, you can skip the headaches of technical updates, unwanted intrusions, malfunctioning features, or the intricacies of integrating SEO-friendly components to your site.</p>
                      <p>That's our domain! We understand your website's requirements and have the expertise to execute tasks flawlessly!</p>
                      
                  </Grid>
                  <Grid item md={6} sm={6} xs={12} data-aos="fade-left">
                      <h2>Complete peace of mind that your website is working problem free</h2>
                      <p>We'll oversee, back up, ensure security, enhance site speed, and even make edits using the designated time included in your selected plan.</p>
                      <p>We present three distinct WordPress website maintenance packages, each tailored to provide the essentials to keep your website operational, seamless, and perpetually secure.</p>
                      <p>From quaint blogs to expansive corporate sites, our services encompass all. Should you have unique needs, please reach out, and we'll gladly craft a custom package just for you.</p>
                      
                  </Grid>  
                  <Grid item md={6} sm={6} xs={12} data-aos="fade-right">
                      <img src={relax} alt="peace of mind" />
                  </Grid>
                          
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <div data-aos="fade-down">
        <Faqs />
      </div>
      <div id="ctaform" data-aos="fade-up" >
      <CtaForm />
      </div>
    </div >
  )
}

export default CarePlan
